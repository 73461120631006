// Packages
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


// Components
import Loading from 'components/UI/Loading';
import Message from 'components/UI/Message';


// Other
import Api from 'other/Api';


const Screen = () => {

	// URL Parameters
	const { objectName } = useParams();


	// State
	const [ changeset, setChangeset ] = useState();
	const [ loading, setLoading ] = useState(true);

	const [ current, setCurrent ] = useState();
	const [ currentLoading, setCurrentLoading ] = useState(true);

	const [ errorMessage, setErrorMessage ] = useState('');
	const [ successMessage, setSuccessMessage ] = useState('');


	useEffect(() => {

		/**
		 * Get new changeset
		 */
		Api.changesets.get(objectName, {
			success: (response) => {
				setChangeset(response.data);
			},
			error: (response) => {
				setErrorMessage(response.error);
			},
			after: () => {
				setLoading(false);
			}
		});


		/**
		 * Get current data
		 */
		Api.changesets.get('current', {
			before: () => {
				setCurrentLoading(true);
			},
			success: (response) => {
				setCurrent(response.data);
			},
			error: (response) => {
			},
			after: () => {
				setCurrentLoading(false);
			}
		});

	}, [objectName]);


	/**
	 * Return the full country name
	 */
	const getCountryName = (name) => {

		switch(name)
		{
			case 'global': 
				return 'Global';
			case 'spain' : 
				return 'Spain';
			case 'france' : 
				return 'France';
			case 'italy':
				return 'Italy';
			case 'usa' : 
				return 'USA';
			case 'uk':
				return 'UK';
			case 'chile' : 
				return 'Chile';
			case 'japan': 
				return 'Japan';
			case 'southafrica':
				return 'South Africa';
			default:
				return name;
		}
	}


	/**
	 * Accept the changeset
	 */
	const accept = () => {

		Api.changesets.accept(objectName, {
			before: () => {
				setLoading(true);
			},	
			success: (response) => {
				setSuccessMessage("The changes have been accepted. It can take up to 10 minutes before they show on the website.");
				setChangeset(response.data);
			},
			error: (response) => {
				setErrorMessage(response.error);
			},
			after: () => {
				setLoading(false);
			}
		})
	}

	const showValue = (group, parameter) => {

		let className = '';;
		let currentValue;

		if ( current && current[group] && current[group][parameter] && current[group][parameter] !== changeset[group][parameter] )
		{
			className = 'changed';
			currentValue = current[group][parameter];
		}
	
		return (
			<td className={`r ${className}`}>
				{ currentValue &&
					<span className="current-value">{currentValue}</span>
				}
				{changeset[group][parameter]}
				
			</td>
		);

	}


	if ( loading )
	{
		return (<Loading/>);
	}


	if ( errorMessage )
	{
		return (<Message type="error" text={errorMessage}/>);
	}


	return (
		<>
		<h1>Dashboard Stats Change</h1>

		<p>The following table is the latest information from SmartSheet. 
			Highlighted cells differ from the current version.

			{ currentLoading &&
				<>Loading current data</>
			}
		</p>

		{ successMessage &&
			<Message type="ok" text={successMessage}/>
		}
		
		<table className="table">
		<thead>
		<tr>
			<th>Country</th>
			<th className="r">Capacity</th>
			<th className="r">Operational</th>
			<th className="r">Construction</th>
			<th className="r">Development</th>
			<th className="r">Pipeline</th>
			<th className="r">Total Development*</th>
			<th className="r">Projects</th>
			<th className="r">Employees</th>
		</tr>
		</thead>

		<tbody>
		{Object.keys(changeset).map((key, index) => {

			if ( key === 'meta' )
			{
				return (<></>);
			}

			return (
				<tr key={key} className={`country-${key}`}>
					<td>{getCountryName(key)}</td>
					{showValue(key, 'total_capacity')}
					{showValue(key, 'total_operational')}
					{showValue(key, 'total_construction')}
					{showValue(key, 'development')}
					{showValue(key, 'pipeline')}
					{showValue(key, 'total_development')}
					{showValue(key, 'num_projects')}
					{showValue(key, 'num_employees')}
				</tr>
			);
		})}
		</tbody>

		</table>

		<p>* Total Development = Development + Pipeline.</p>

		<br/>

		<button onClick={accept} className="button">Update Stats on Website</button>

		</>
	)
}

export default Screen;