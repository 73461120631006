// Assets
import './styles.css';
import logo from './logo.png';


const Header = () => {

	return (
		<header className="Header">
			<div className="container">
				<img src={logo} alt="Sonnedix" className="Header__logo"/>
			</div>
		</header>
	);
}

export default Header;