// Routes
import MainRoutes from 'routes/main';


// Assets
import './assets/styles.css';


const App = () => 
{

  return (
       <MainRoutes/>
  );

}

export default App;