// Components
import Header from 'components/Header'
import Footer from 'components/Footer'


// Assets
import './styles.css'



const DefaultLayout = props => {

	return (
		<div className="DefaultLayout">
			<div className="DefaultLayout__header">
				<Header/>
			</div>
			<div className="DefaultLayout__body">
				<div className="container">
				{props.children}
				</div>
			</div>	
			<div className="DefaultLayout__footer">
				<Footer/>
			</div>	
		</div>
	)
}


export default DefaultLayout