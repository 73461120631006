/*

Main configuration file for this app

React only allows two environments natively (dev and prod), so
we have to manage our own env config :(

*/


/*
General Configuration
*/
const generalConfig = {
	STAGE: process.env.REACT_APP_STAGE
}


/*
Environment-specific Configuration
*/
const environmentConfig = {

	/*
	Production Environment Configuration
	*/
	production: {
		API_URL: "https://api.stats.sonnedix.btd.services"

	},
	
	/*
	Development Environment Configuration
	*/
	development: {
		API_URL: "https://api.stats.sonnedix.btd.services"
	}

}

const config = {
	...generalConfig,
	...environmentConfig[process.env.REACT_APP_STAGE]
}

export default config