// Packages
import axios from 'axios';


// Other
import Config from 'other/Config';


/** 
 * Adds defaults to all requests
 */
const processRequest = (args) => {

    if ( ! args.before )
    {
        args.before = () => { };
    }

    if ( ! args.success )
    {
        args.success = () => { alert('There is no success callback')};
    }

    if ( ! args.error )
    {
        args.error = (error) => { alert('An unhandled error occurred: ' + error.message)};
    }

    if ( ! args.after )
    {
        args.after = () => { };
    }

    if ( ! args.params )
    {
        args.params = {};
    }

    return args;
}


/**
 * The code that actually makes the API calls
 */
const apiRequest = (method, url, request) => {

    const params = processRequest(request);

    params.before();

    axios.request({
        url: Config.API_URL + url,
        method: method.toLowerCase(),
        params: params.params,
        data: params.data,
        responseType: 'json'
    })
    .then((response) => {
        params.success(response.data);
    })
    .catch((exception) => {
        params.error(exception.response.data);
    })
    .then(() => {
        params.after()
    });
}


/**
 * Changesets
 * --------------------------------------------------------
 */

const getChangeset = async (id, request) => {
    apiRequest('get', '/changesets/' + id + "?ts=" + Date.now(), request);
}


const acceptChangeset = async (id, request) => {
    apiRequest('post', '/changesets/' + id + '/approve', request);
}



const output = {
    changesets: {
        get: getChangeset,
        accept: acceptChangeset
    }
}


export default output;