// Packages
import { Routes, Route } from "react-router-dom"


// Layouts
import DefaultLayout from 'layouts/Default';


// Screens
import ViewChangesetScreen from 'screens/Changeset/View';


const MainRoutes = () => 
{
	return (
		<DefaultLayout>
		<Routes>
			<Route path="/:objectName" element={<ViewChangesetScreen/>}/>
		</Routes>
		</DefaultLayout>
	);
}


export default MainRoutes;