
// Assets
import './styles.css';
import logo from './logo.svg';


const Footer = () => {

	return (
		<header className="Footer">
			<div className="container">
				<a href="https://behindthedot.com" target="_blank" rel="noreferrer"><img src={logo} alt="Behind The Dot" className="Footer__logo"/></a>
			</div>
		</header>
	);
	
}

export default Footer;